import React from "react"
import Layout from "../components/layout"
import Menu from "../components/global/Menu"
import Footer from "../components/global/Footer"
import SEO from "../components/seo"
import ReactMarkdown from "react-markdown"
import { graphql } from "gatsby"

const JobPost = ({ data: { strapiJobs } }) => (
  <Layout bodyTheme="theme-about">
    <SEO title={strapiJobs["Title"]} />
    <header>
      <Menu />
    </header>
    <main className="page-wrapper">
      <section className="section section-first">
        <div className="jobs-article-content">
          <div className="jobs-article-content-text">
            <h1>{strapiJobs["Title"] || "N/A"}</h1>

            <p>
              <strong>{strapiJobs["Location"]["Title"] || "N/A"}</strong>
              <br />
              {strapiJobs["Location"]["Content"] || "N/A"}
            </p>

            <p>
              <strong>{strapiJobs["Compensation"]["Title"] || "N/A"}</strong>
            </p>

            <ReactMarkdown source={strapiJobs["Company"][["AboutCompany"]]} />

            <h4>{strapiJobs["JobDescription"]["Title"] || "N/A"}</h4>
            <ReactMarkdown
              source={strapiJobs["JobDescription"]["Content"] || "N/A"}
            />

            <h4>{strapiJobs["RequiredSkills"]["Title"] || "N/A"}</h4>
            <ReactMarkdown
              source={strapiJobs["RequiredSkills"]["Content"] || "N/A"}
            />

            <h4>{strapiJobs["APlusExperience"]["Title"] || "N/A"}</h4>
            <ReactMarkdown
              source={strapiJobs["APlusExperience"]["Content"] || "N/A"}
            />

            <ReactMarkdown source={strapiJobs["Travel"] || "N/A"} />

            <p>
              <a href="https://www.razroo.com">www.razroo.com</a>
            </p>

            <h5>{strapiJobs["ContactInformation"]["Title"] || "N/A"}</h5>
            <ReactMarkdown
              source={strapiJobs["ContactInformation"]["Content"]}
            />
          </div>
        </div>
      </section>
      <Footer />
    </main>
  </Layout>
)

export const query = graphql`
  query($id: String!) {
    strapiJobs(id: { eq: $id }) {
      id
      Title
      Company {
        AboutCompany
      }
      ContactInformation {
        Content
        Title
      }
      Compensation {
        Content
        Title
      }
      FullTime
      JobDescription {
        Content
        Title
      }
      Location {
        Content
        Title
      }
      Remote
      RequiredSkills {
        Content
        Title
      }
      APlusExperience {
        Content
        Title
      }
      Travel
    }
  }
`

export default JobPost
